(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

module.exports = function ($scope, ProductService, OrderService) {

  ProductService.get().success(function (data) {
    $scope.products = data;
  });

  $scope.position = 0;
  $scope.trade_orders = [];
  $scope.currentOrder = {
    product_id: null,
    product_name: null,
    quantity: 0,
    unit_price: null,
    total_cost: null
  };

  $scope.productChange = function () {
    var order = $scope.currentOrder;
    order.product_id = $scope.product.id;
    order.product_name = $scope.product.name;
    order.unit_price = $scope.product.currency[0].pivot.price;
    order.total_cost = order.unit_price * $scope.currentOrder.quantity;
  };

  $scope.addProduct = function () {
    $scope.trade_orders.push($scope.currentOrder);
    $scope.currentOrder = {
      product_id: null,
      product_name: null,
      quantity: 0,
      unit_price: null,
      total_cost: null
    };
  };

  $scope.removeProduct = function (index) {
    $scope.trade_orders.splice(index, 1);
  };

  $scope.finished = false;
  $scope.finish = function () {
    $scope.finished = true;
  };

  $scope.totalPrice = function () {
    if (!$scope.trade_orders.length) return 0;
    return $scope.trade_orders.map(function (t) {
      return t.unit_price * t.quantity;
    }).reduce(function (a, b) {
      return a + b;
    });
  };

  $scope.send = function () {

    OrderService.send($scope.trade_orders, $scope.order.number, $scope.order.destination, $scope.order.by).success(function () {
      window.location.href = '/trade/thank-you';
    });
  };
};

},{}],2:[function(require,module,exports){
'use strict';

module.exports = name = 'odl.controllers';

angular.module('odl.controllers', []).controller('OrderController', require('./OrderController'));

},{"./OrderController":1}],3:[function(require,module,exports){
'use strict';

angular.module('odl', [require('./controllers'), require('./services')]).config(function ($interpolateProvider) {
    $interpolateProvider.startSymbol('#{').endSymbol('}');
});

},{"./controllers":2,"./services":6}],4:[function(require,module,exports){
'use strict';

module.exports = function ($http) {
	return {
		send: function send(orders, number, destination, by) {
			return $http.post('/trade/new-order/submit', {
				orders: orders,
				number: number,
				by: by,
				destination: destination
			});
		}
	};
};

},{}],5:[function(require,module,exports){
'use strict';

module.exports = function ($http) {
	return {
		get: function get() {
			return $http.get('/trade/api/products');
		}
	};
};

},{}],6:[function(require,module,exports){
'use strict';

module.exports = name = 'odl.services';

angular.module(name, []).factory('ProductService', require('./ProductService')).factory('OrderService', require('./OrderService'));

},{"./OrderService":4,"./ProductService":5}]},{},[3]);
